// vendors
import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import mapStyles from './mapStyles.json';

const defaultOptions = {
  center: { lat: 46.78555345, lng: -71.27566505 },
  zoom: 12,
  styles: mapStyles,
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

const defaultLabel = {
  color: '#fff',
  text: 'MQ',
  fontFamily: 'IBM Plex Sans',
  fontSize: '16px',
  fontWeight: '600',
};

const Map = ({ markers, options, onMarkerListRendered }) => {
  const mapElRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);

  const renderMarkers = useCallback(() => {
    if (window && window.google && mapRef) {
      const defaultIcon = {
        path:
          'M10.17,27.34s10.17-9.47,10.17-17.17A10.17,10.17,0,0,0,0,10.17c0,7.7,10.17,17.17,10.17,17.17',
        fillColor: '#00aeef',
        fillOpacity: 1,
        anchor: new window.google.maps.Point(10.152, 27.34),
        strokeWeight: 0,
        scale: 2,
        labelOrigin: new window.google.maps.Point(10.152, 11.152),
      };

      markersRef.current = markers.map((marker) => {
        return new window.google.maps.Marker({
          position: { lat: marker.position.lat, lng: marker.position.lng },
          icon: defaultIcon,
          label: defaultLabel,
          map: mapRef.current,
          ...marker,
        });
      });

      onMarkerListRendered(markersRef.current);
    }
  }, [markers, mapRef, onMarkerListRendered]);

  const initMap = useCallback(() => {
    if (window && window.google) {
      mapRef.current = new window.google.maps.Map(mapElRef.current, {
        ...defaultOptions,
        ...options,
      });

      renderMarkers();
    }
  }, [mapElRef, options, renderMarkers]);

  useEffect(() => {
    let script = document.getElementById('google-map-script-loader');

    if (script) initMap();

    if (!script) {
      script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GCP_API_KEY}`;
      script.defer = true;
      script.async = true;
      script.id = 'google-map-script-loader';

      document.head.appendChild(script);
    }

    script.addEventListener('load', initMap);

    return () => script.removeEventListener('load', initMap);
  }, [initMap]);

  return <div style={{ width: '100%', height: '100%' }} ref={mapElRef} />;
};

Map.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        lat: PropTypes.string.isRequired,
        lng: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  options: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.string.isRequired,
      lng: PropTypes.string.isRequired,
    }),
    zoom: PropTypes.number,
  }),
  onMarkerListRendered: PropTypes.func,
};

Map.defaultProps = {
  markers: [],
  options: {},
  onMarkerListRendered: () => {},
};

export default Map;
