// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampCliniques = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M3.3 74.4L5 69a7.8 7.8 0 011.4-2.6 5.8 5.8 0 012.2-1.5 7 7 0 012.9-.5 11.6 11.6 0 013.6.6 11.6 11.6 0 013.2 1.7 7 7 0 012 2.1 5.7 5.7 0 01.8 2.6 7.6 7.6 0 01-.4 2.9l-2 5.5zm15.2-1a4 4 0 00-.1-3.2 4.7 4.7 0 00-2.9-2.1l-2.5-.9a4.6 4.6 0 00-3.6 0 4 4 0 00-2 2.4l-1 3 11.1 3.8z' />
      <path d='M27.8 59.8a6 6 0 01-1.5 1.8 4.8 4.8 0 01-2 .9 5.7 5.7 0 01-2.4 0 8.4 8.4 0 01-2.5-1 8.6 8.6 0 01-2-1.7 5.7 5.7 0 01-1.3-2 4.9 4.9 0 01-.2-2.2 5.8 5.8 0 01.8-2.3 5.4 5.4 0 011.6-1.8 4.7 4.7 0 012-.8 5.7 5.7 0 012.2 0 7.7 7.7 0 012.2 1l.9.4-4.3 7.4.4.2a3.3 3.3 0 002.4.4 3.1 3.1 0 002-1.6 3.5 3.5 0 00.6-2 4.5 4.5 0 00-.5-1.8l2-.5a5 5 0 01.6 2.6 6.5 6.5 0 01-1 3zm-9.4-5.4a3 3 0 00-.4 1.1 2.8 2.8 0 00.1 1.2 2.9 2.9 0 00.6 1 3.7 3.7 0 001 .8h.1l2.9-5h-.2A3.4 3.4 0 0020 53a2.5 2.5 0 00-1.7 1.4z' />
      <path d='M36.2 44.7v.1a4.6 4.6 0 01.3 1 2.8 2.8 0 01.1 1 3.5 3.5 0 01-.3 1 4.7 4.7 0 01-.7 1.1 3.7 3.7 0 01-2.9 1.5 5.2 5.2 0 01-3.3-1.5l-6-5.2 1.8-2 5.6 5c1.5 1.2 2.7 1.2 3.7 0a4.3 4.3 0 00.6-.8 2.7 2.7 0 00.2-.9 2 2 0 000-.8 2 2 0 00-.7-.8L28.4 38l1.7-2 9.3 8-1.7 2z' />
      <path d='M41.9 41.6l-.5-7.5-7-2.2 2.3-1.8 4.8 1.6-.3-5.1 2.1-1.7.5 7.3 7.2 2.3-2.4 1.8-5-1.7.4 5.3z' />
      <path d='M64.3 27.5a5.9 5.9 0 01-2.4.6 4.5 4.5 0 01-2.1-.5 5.8 5.8 0 01-1.8-1.4 9.2 9.2 0 01-1.5-2.2 9 9 0 01-.7-2.6 6 6 0 01.1-2.3 4.8 4.8 0 011.1-1.9 5.8 5.8 0 012-1.4 5.1 5.1 0 013.1-.5 4.7 4.7 0 012.5 1.3L63 18.4a2.5 2.5 0 00-3.2-.6 2.6 2.6 0 00-1.5 1.7 3.5 3.5 0 00.3 2.4l.9 2a3.5 3.5 0 001.6 1.8 2.6 2.6 0 002.3-.1 2.7 2.7 0 001.4-1.3 3.8 3.8 0 00.4-1.7h2.2a5 5 0 01-.8 3 5.4 5.4 0 01-2.3 2z' />
      <path d='M74.6 23.5a2.7 2.7 0 01-2.1-.1 2.6 2.6 0 01-1.2-1.6l-4-14.4 2.5-.7L74 21.5l1.6-.5.6 2z' />
      <path d='M78.4 7.7a1.6 1.6 0 01-1.2-.2 1.4 1.4 0 01-.5-.9v-.3a1.4 1.4 0 01.1-1 1.6 1.6 0 011-.6 1.5 1.5 0 011.3.1 1.4 1.4 0 01.5 1V6a1.4 1.4 0 01-.1 1 1.5 1.5 0 01-1 .6zm-.9 2.4l2.6-.5 2.1 12.1-2.5.5z' />
      <path d='M87 20.6l-.3-12.2 2.6-.1v2h.2a4 4 0 011.1-1.7A3.4 3.4 0 0193 8a3.7 3.7 0 013 1.2 5.3 5.3 0 011.1 3.5l.2 7.8h-2.6l-.2-7.4c0-2-.9-2.9-2.4-2.8a3.6 3.6 0 00-1 .1 2.7 2.7 0 00-.9.4 1.9 1.9 0 00-.7 1.7l.2 8.2z' />
      <path d='M102.8 8.3l2.6.2-1 12.3-2.5-.2zm1.5-2a1.5 1.5 0 01-1.1-.5 1.3 1.3 0 01-.3-1v-.4a1.3 1.3 0 01.4-1 1.5 1.5 0 011.2-.2 1.5 1.5 0 011.1.4 1.3 1.3 0 01.3 1V5a1.4 1.4 0 01-.4 1 1.5 1.5 0 01-1.2.3z' />
      <path d='M117.3 21h-.1a3.5 3.5 0 01-1.7 1.4 3.8 3.8 0 01-2.2 0 4.3 4.3 0 01-3.1-2.4 7.7 7.7 0 01-.2-4.9 7.8 7.8 0 012.3-4.3 4.2 4.2 0 014-.9 3.8 3.8 0 012 1.1 3.4 3.4 0 01.8 2h.1l.5-2 2.5.6-3.9 16.5-2.5-.5zm-2.7-.5a3.3 3.3 0 002-.2 1.8 1.8 0 001.2-1.3l1-4a1.8 1.8 0 00-.5-1.7 3.4 3.4 0 00-1.8-1 2.8 2.8 0 00-2.3.3 3.3 3.3 0 00-1.3 2l-.5 2.1a3.2 3.2 0 00.3 2.4 2.8 2.8 0 001.9 1.3z' />
      <path d='M131.7 26.1h-.1a4.6 4.6 0 01-.8.7 3 3 0 01-1 .4 3.1 3.1 0 01-1 0 4.4 4.4 0 01-1.3-.4 3.7 3.7 0 01-2.2-2.3 5.3 5.3 0 01.5-3.6l3.2-7.2 2.4 1-3.1 6.9q-1.2 2.6 1 3.6a4.3 4.3 0 00.9.2 2.8 2.8 0 001 0 2 2 0 00.7-.3 2 2 0 00.6-.8l3.4-7.5 2.3 1-5 11.3-2.4-1.1z' />
      <path d='M141.3 34.3a6.2 6.2 0 01-1.6-1.8 5 5 0 01-.7-2 5.7 5.7 0 01.2-2.4 8.4 8.4 0 011.3-2.4 8.2 8.2 0 011.8-1.9 5.8 5.8 0 012.1-1 4.9 4.9 0 012.2 0 6 6 0 012.2 1 5.6 5.6 0 011.7 1.8 4.7 4.7 0 01.6 2 5.8 5.8 0 01-.3 2.2 8.4 8.4 0 01-1.1 2.1l-.6.8-6.8-4.9-.3.4a3.2 3.2 0 00-.6 2.4 3.1 3.1 0 001.4 2 3.5 3.5 0 001.8.8 4.2 4.2 0 001.9-.4l.4 2a5 5 0 01-2.7.4 6.6 6.6 0 01-2.9-1.1zm6.3-8.9a3 3 0 00-1.1-.5 2.6 2.6 0 00-1.1 0 3 3 0 00-1.1.5 3.9 3.9 0 00-1 1l4.6 3.4.1-.2a3.4 3.4 0 00.8-2.3 2.5 2.5 0 00-1.2-1.9z' />
      <path d='M151.8 42.6A6.2 6.2 0 01150 40a6.4 6.4 0 01-.3-2.6h2.3a5.3 5.3 0 00.2 2 4 4 0 001 1.7 2.7 2.7 0 001.6.9 1.5 1.5 0 001.3-.5 1.4 1.4 0 00.4-1 2.2 2.2 0 00-.7-1.4l-.7-.9a5.8 5.8 0 01-1.4-2.8 3 3 0 011-2.6 3.7 3.7 0 011.4-1 3.4 3.4 0 011.5-.1 4.2 4.2 0 011.5.4 5.8 5.8 0 011.5 1.1 6.1 6.1 0 011.6 2.3 6.3 6.3 0 01.4 2.3h-2.2a4.2 4.2 0 00-.2-1.5 3.6 3.6 0 00-1-1.6 2.5 2.5 0 00-1.4-.7 1.4 1.4 0 00-1.2.4 1.3 1.3 0 00-.4 1 3.4 3.4 0 00.7 1.5l.7.8a5.5 5.5 0 011.4 3 3.1 3.1 0 01-1 2.3 3.8 3.8 0 01-1.4 1 3.6 3.6 0 01-1.5.1 4.4 4.4 0 01-1.7-.4 6.7 6.7 0 01-1.7-1.2z' />
      <path d='M172.7 47.5l1.4 2.2-1.8 1 .1.2a3.3 3.3 0 012.2.2 3.8 3.8 0 011.6 1.4 4.3 4.3 0 01.6 4 9.5 9.5 0 01-8 5.2 4.3 4.3 0 01-3.4-2.2 3.7 3.7 0 01-.7-2.1 3.4 3.4 0 01.8-2l-.1-.1-5.7 3.6-1.4-2.1zm-5.8 10.1a2.8 2.8 0 001.8 1.4 3.2 3.2 0 002.4-.6l1.8-1.1a3.3 3.3 0 001.5-2 2.8 2.8 0 00-.5-2.2 3.4 3.4 0 00-1.5-1.4 1.8 1.8 0 00-1.7.2l-3.6 2.3a1.8 1.8 0 00-.8 1.5 3.3 3.3 0 00.6 2z' />
      <path d='M171.5 71.8a6 6 0 01-.5-2.3 5 5 0 01.6-2.1 6.3 6.3 0 011.4-1.8 9 9 0 015-2 6 6 0 012.3.3 5 5 0 011.9 1.2 6.2 6.2 0 011.7 4.4 4.9 4.9 0 01-.6 2 6 6 0 01-1.5 1.9 8.3 8.3 0 01-2.3 1.3 8.2 8.2 0 01-2.6.6 6 6 0 01-2.3-.3 4.8 4.8 0 01-1.9-1.2 5.8 5.8 0 01-1.2-2zm2-.7a2.9 2.9 0 001.5 1.6 3.3 3.3 0 002.6-.1l1.9-.8a3.3 3.3 0 002-1.6 2.8 2.8 0 000-2.3 2.8 2.8 0 00-1.6-1.7 3.4 3.4 0 00-2.5.2l-2 .7a3.3 3.3 0 00-2 1.7 3 3 0 000 2.3z' />
      <path d='M178.2 87.9v-.1a4.6 4.6 0 01-1-.3 3.3 3.3 0 01-.8-.5 3.3 3.3 0 01-.7-1 4.4 4.4 0 01-.4-1.2 3.7 3.7 0 01.7-3.1 5.2 5.2 0 013.3-1.7l7.7-1.4.4 2.6-7.4 1.3c-1.8.3-2.6 1.2-2.4 2.8a3.9 3.9 0 00.3 1 2.7 2.7 0 00.6.7 2 2 0 00.7.5 1.9 1.9 0 001 0l8.1-1.5.5 2.6-12.1 2.1-.5-2.5z' />
      <path d='M177 95.6l12.4-.3V98h-2.3v.2a3.3 3.3 0 011.6 1 3.4 3.4 0 01.8 2.3v.7H187v-1a4.7 4.7 0 00-.5-2.3 1.6 1.6 0 00-1.5-.8l-7.8.2z' />
      <path d='M176 115.2l13-1.3-.7 2.5-5.8.6-4.5.2v.2l3.9 2.1 5 3.2-.6 2.4-11-7z' />
      <path d='M171.6 129.8a6 6 0 011.2-2 4.9 4.9 0 011.9-1.2 6 6 0 012.3-.3 8.2 8.2 0 012.6.6 8.5 8.5 0 012.3 1.3 5.9 5.9 0 011.5 1.8 5 5 0 01.6 2.1 6.3 6.3 0 01-1.7 4.4 5 5 0 01-1.9 1.2 6.2 6.2 0 01-2.3.3 8.4 8.4 0 01-2.6-.6 8.3 8.3 0 01-2.3-1.4 6 6 0 01-1.5-1.8 4.9 4.9 0 01-.6-2.1 5.9 5.9 0 01.5-2.3zm2 .7a2.9 2.9 0 000 2.3 3.3 3.3 0 002 1.7l1.9.7a3.3 3.3 0 002.5.2 2.8 2.8 0 001.6-1.7 2.9 2.9 0 000-2.3 3.3 3.3 0 00-2-1.6l-2-.8a3.3 3.3 0 00-2.5-.1 3 3 0 00-1.5 1.6z' />
      <path d='M165.7 146.2v-.1a5.3 5.3 0 01-.4-.9 3.1 3.1 0 01-.3-1 3 3 0 01.1-1 4.2 4.2 0 01.6-1.3 3.7 3.7 0 012.6-1.8 5.3 5.3 0 013.6 1l6.5 4.2-1.3 2.2-6.4-4c-1.6-1-2.8-1-3.6.4a3.3 3.3 0 00-.4.9 2.6 2.6 0 00-.2.9 2.2 2.2 0 00.3.9 2 2 0 00.6.7l7 4.4-1.4 2.2-10.3-6.7 1.4-2.1z' />
      <path d='M157 153.9a6.3 6.3 0 012.3-1.8 6.5 6.5 0 012.6-.5l.1 2.3a5.5 5.5 0 00-2 .3 4 4 0 00-1.6 1.2 2.8 2.8 0 00-.8 1.5 1.4 1.4 0 00.6 1.2 1.4 1.4 0 001 .4 2.1 2.1 0 001.3-.7l.8-.8a5.8 5.8 0 012.8-1.5 3 3 0 012.6.9 3.7 3.7 0 011 1.3 3.3 3.3 0 01.3 1.5 4.2 4.2 0 01-.4 1.5 6.6 6.6 0 01-1 1.6 6.1 6.1 0 01-2.2 1.7 6.4 6.4 0 01-2.3.5v-2.2a4 4 0 001.5-.3 3.6 3.6 0 001.5-1 2.5 2.5 0 00.7-1.5 1.4 1.4 0 00-.5-1 1.3 1.3 0 00-1.1-.4 3.2 3.2 0 00-1.4.7l-.8.8a5.4 5.4 0 01-2.9 1.4 3.1 3.1 0 01-2.4-.8 3.8 3.8 0 01-1-1.3 3.5 3.5 0 01-.3-1.6 4.6 4.6 0 01.4-1.7 6.5 6.5 0 011.1-1.7z' />
      <path d='M138.3 169.9a2 2 0 011.6-.4 2.5 2.5 0 011.4 1v-.2a3 3 0 01.2-2.1 4.3 4.3 0 011.6-1.8 4 4 0 013-.7 3.5 3.5 0 012.3 1.7 3.1 3.1 0 01.4 3 6.9 6.9 0 01-2.8 3l-1.9 1.1.6.9a2.4 2.4 0 001.4 1.1 2.6 2.6 0 002-.5 3 3 0 001.2-1.3 4.5 4.5 0 00.3-1.7l2 .4a5 5 0 01-.5 2.3 5.5 5.5 0 01-2 2.2 5.5 5.5 0 01-3.8 1 4 4 0 01-2.7-2l-3.4-5.2-1 .7-1.2-1.8zm5.3-1.4a3.4 3.4 0 00-1.4 1.5 1.7 1.7 0 000 1.6l1 1.5 2-1.1c1.4-1 1.9-2 1.3-2.9l-.3-.3a1.5 1.5 0 00-1.1-.8 2.5 2.5 0 00-1.5.5z' />
      <path d='M129.4 174.2a6 6 0 012.3-.6 4.7 4.7 0 012.1.5 5.9 5.9 0 011.9 1.4 8.6 8.6 0 011.4 2.2 9 9 0 01.8 2.6 5.8 5.8 0 01-.2 2.3 4.7 4.7 0 01-1 2 6 6 0 01-2 1.3 5.2 5.2 0 01-3.1.5 4.8 4.8 0 01-2.5-1.3l1.5-1.8a2.6 2.6 0 001.4.9 2.7 2.7 0 001.8-.3 2.6 2.6 0 001.6-1.7 3.6 3.6 0 00-.3-2.3l-1-2a3.5 3.5 0 00-1.5-1.8 2.6 2.6 0 00-2.4 0 2.8 2.8 0 00-1.4 1.3 3.8 3.8 0 00-.3 1.8l-2.3-.2a5 5 0 01.8-2.8 5.4 5.4 0 012.4-2z' />
      <path d='M117.1 178.5a5.8 5.8 0 012.4-.2 4.6 4.6 0 012 .8 5.8 5.8 0 011.6 1.6 8.8 8.8 0 011 2.5 8.8 8.8 0 01.5 2.6 6 6 0 01-.5 2.3 4.6 4.6 0 01-1.4 1.7 5.9 5.9 0 01-2.1 1 5.3 5.3 0 01-3.2 0 4.8 4.8 0 01-2.2-1.6l1.8-1.5a2.7 2.7 0 001.2 1 2.8 2.8 0 001.8 0 2.6 2.6 0 001.8-1.4 3.5 3.5 0 000-2.4l-.5-2a3.5 3.5 0 00-1.4-2 2.6 2.6 0 00-2.2-.4 2.8 2.8 0 00-1.6 1 3.9 3.9 0 00-.7 1.8l-2.1-.5a4.9 4.9 0 011.1-2.7 5.3 5.3 0 012.7-1.6z' />
      <path d='M101.6 183.3h.1a4.7 4.7 0 01.4-1 3.1 3.1 0 01.6-.7 3 3 0 011-.6 4.2 4.2 0 011.3-.3 3.8 3.8 0 013 1 5.3 5.3 0 011.4 3.5l.6 7.8-2.6.2-.5-7.5c-.2-2-1-2.8-2.6-2.7a3.8 3.8 0 00-1 .2 2.6 2.6 0 00-.8.5 2 2 0 00-.6.7 1.9 1.9 0 00-.1 1l.6 8.2-2.6.2-.9-12.3 2.6-.2z' />
      <path d='M89.3 180.8a5.8 5.8 0 012.3.6 4.8 4.8 0 011.6 1.4 5.7 5.7 0 011 2.1 8.9 8.9 0 01.2 2.7 8.7 8.7 0 01-.6 2.7 5.8 5.8 0 01-1.2 1.9 4.8 4.8 0 01-1.9 1.1 5.8 5.8 0 01-2.4.3 5.5 5.5 0 01-2.3-.6 4.7 4.7 0 01-1.6-1.4 5.6 5.6 0 01-.9-2 7.9 7.9 0 01-.1-2.4v-1l8.4.6v-.4a3.2 3.2 0 00-.6-2.4 3.1 3.1 0 00-2.4-1 3.5 3.5 0 00-2 .3 4.1 4.1 0 00-1.4 1.2L84 183a4.8 4.8 0 012.2-1.6 6.3 6.3 0 013-.5zm-.9 10.8a3.1 3.1 0 001.3-.1 2.7 2.7 0 001-.6 3 3 0 00.6-1 3.7 3.7 0 00.4-1.3v-.2L86 188v.2a3.5 3.5 0 00.6 2.4 2.5 2.5 0 001.8 1z' />
      <path d='M75.3 193.5a1.6 1.6 0 011 .6 1.3 1.3 0 01.2 1v.4a1.4 1.4 0 01-.7.9 1.6 1.6 0 01-1.2 0 1.6 1.6 0 01-1-.6 1.4 1.4 0 01-.1-1v-.4a1.3 1.3 0 01.6-.8 1.5 1.5 0 011.2-.1zm1.8-1.9l-2.5-.5 2.7-12 2.5.5z' />
      <path d='M70.2 177a2.3 2.3 0 011.6 3.2L67 194.3l-2.4-.8 5-14.5-1.7-.6.7-2z' />
      <path d='M63 174a2.3 2.3 0 011.3 3.4L58.2 191l-2.4-1 6.3-14-1.5-.7.9-2z' />
      <path d='M49.9 183.6a1.5 1.5 0 01.8.9 1.3 1.3 0 01-.2 1l-.2.3a1.3 1.3 0 01-.8.7 2 2 0 01-2-1.2 1.3 1.3 0 01.2-1l.2-.3a1.4 1.4 0 01.8-.7 1.5 1.5 0 011.2.3zm2.2-1.3l-2.2-1.3 6.1-10.6 2.2 1.3z' />
      <path d='M51.9 167.8l-7.2 10-2-1.5 1.3-1.9h-.1a3.3 3.3 0 01-1.8.7 3.4 3.4 0 01-2.3-.8l-.6-.4 1.5-2 .8.6a4.6 4.6 0 002.2 1 1.6 1.6 0 001.5-.7l4.5-6.4z' />
      <path d='M33.1 165.6a88 88 0 01-27-63.7A90 90 0 017.4 88l2.1.3a87 87 0 00-1 13.5A85.9 85.9 0 0034.7 164z' />
    </RoundContainer>

    <path d='M110.8 113.7H82.5L85 78h23.1zm-25.9-2.3h23.5l-2.2-31.2h-19z' />
    <path d='M113.6 137.5H79.7a1.1 1.1 0 01-1-1.7l2.4-4a1.1 1.1 0 011-.6h29.1a1 1 0 011 .5l2.4 4.1a1.1 1.1 0 01-1 1.7zm-32-2.2h30l-1-1.9H82.8z' />
    <path d='M116.4 120.3H76.8v-8.7h39.6zM79 118h35.2v-4.3H79z' />
    <path d='M103.7 74.8h-14l1-14h11.9zM92 72.6h9.3l-.8-9.6h-7.7z' />
    <path d='M95.6 74.8h2.2V79h-2.2z' />
    <path d='M95.6 119.7h2.2v11.5h-2.2z' />
    <path d='M116.5 111.6h-2.2v-6.2a5.3 5.3 0 011.2-3.7 5 5 0 014-1.5h2.7v2.2h-2.8a3 3 0 00-2.3.8 3.3 3.3 0 00-.6 2.1z' />
    <path d='M79 111.6h-2.2v-6.2a3.2 3.2 0 00-.7-2.2 3 3 0 00-2.3-.8h-2.7v-2.2h2.7a5 5 0 014 1.5 5.3 5.3 0 011.2 3.8z' />
  </svg>
);

export default StampCliniques;
