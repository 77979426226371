// vendors
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, em } from 'polished';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import colors from '../styles/colors';
import { introStyle, h1Style } from '../styles/global';
import { greaterThan } from '../utils/mediaQuery';
import breakpoints from '../styles/breakpoints';

import iconCup from '../images/iconCup.svg';
import iconHeart from '../images/iconHeart.svg';
import iconTooth from '../images/iconTooth.svg';
import CliniqueList from '../components/CliniqueList';
import HeroWithText from '../components/HeroWithText';
import Map from '../components/Map';
import StampCliniques from '../images/StampCliniques';

import { fontSizes } from '../styles/typography';
import SEO from '../components/SEO';
import { PrivacyPolicyNote } from '../components/PrivacyPolicy';

const AdvantageSection = styled.section`
  padding: ${rem(112)} ${rem(28)} ${rem(72)};

  background-color: ${colors.iceBlue};

  ${greaterThan(breakpoints[3])} {
    padding: ${rem(152)} ${rem(28)} ${rem(136)};
  }
`;

const AdvantageWrapper = styled.div`
  max-width: ${rem(1440)};
  margin: auto;
`;

const AdvantageList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  margin: ${rem(-28)} 0;
  padding: 0;

  list-style: none;
`;

const AdvantageCard = styled.li`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  margin: ${rem(28)};

  font-size: ${em(20, fontSizes.body[0])};
  line-height: ${25 / 20};
  text-align: center;

  ${greaterThan(breakpoints.fonts[0])} {
    font-size: ${em(24, fontSizes.body[1])};
  }

  ${greaterThan(breakpoints.fonts[1])} {
    font-size: ${em(30, fontSizes.body[2])};
  }
`;

const AdvantageImage = styled.img`
  display: block;
  width: ${em(170, 20)};
  max-width: 234px;
  margin: auto;
`;

const AdvantageTitle = styled.p`
  margin: ${em(24, 16)} auto;
`;

const MapContainer = styled.div`
  display: block;
  width: 100vw;
  height: ${(920 / 1920) * 100}vw;
  max-height: 920px;
`;

const CliniquesPage = ({ data }) => {
  const {
    headerImageMobile: {
      childImageSharp: { fluid: headerImageMobile },
    },
    headerImageDesktop: {
      childImageSharp: { fluid: headerImageDesktop },
    },
    cliniques: { edges: cliniques },
  } = data;

  const mapMarkers = cliniques.map(({ node }) => ({
    position: {
      lat: Number(node.address.coordonate.lat),
      lng: Number(node.address.coordonate.lng),
    },
  }));

  return (
    <Layout>
      <SEO
        title='Nos Cliniques'
        description='Nos spécialistes en chirurgie buccale et maxillo-faciale vous accueilleront dans nos cliniques Maxillo Québec Lebourgneuf et Lévis.'
      />

      <article>
        <HeroWithText
          stamp={<StampCliniques />}
          pictureSmall={headerImageMobile}
          pictureLarge={headerImageDesktop}
        >
          <h1 css={h1Style}>
            Nos cliniques à&nbsp;la&nbsp;fine&nbsp;pointe
            de&nbsp;la&nbsp;technologie
          </h1>

          <p css={introStyle}>
            Si vous venez nous visiter à l’une de nos cliniques, vous pouvez
            être assurés que vous serez accueillis dans un environnement pausé
            et calme.
          </p>

          <p>
            Nos bureaux sont à la fine pointe de la technologie et vous y serez
            traités avec respect et empathie. La globalité des services et
            traitements réalisés par nos spécialistes est offerte aux divers
            points de service de la clinique Maxillo Québec.
          </p>
        </HeroWithText>

        <AdvantageSection>
          <AdvantageWrapper>
            <AdvantageList>
              <AdvantageCard>
                <AdvantageImage src={iconCup} alt='' role='presentation' />

                <AdvantageTitle>
                  Un accueil <br />
                  en toute quiétude
                </AdvantageTitle>
              </AdvantageCard>

              <AdvantageCard>
                <AdvantageImage src={iconTooth} alt='' role='presentation' />

                <AdvantageTitle>
                  Une technologie <br />
                  moderne
                </AdvantageTitle>
              </AdvantageCard>

              <AdvantageCard>
                <AdvantageImage src={iconHeart} alt='' role='presentation' />

                <AdvantageTitle>
                  Une approche <br />
                  empathique
                </AdvantageTitle>
              </AdvantageCard>
            </AdvantageList>
          </AdvantageWrapper>
        </AdvantageSection>

        <CliniqueList />

        <PrivacyPolicyNote />

        <MapContainer>
          <Map markers={mapMarkers} />
        </MapContainer>
      </article>
    </Layout>
  );
};

export default CliniquesPage;

CliniquesPage.propTypes = {
  data: PropTypes.shape({
    headerImageMobile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    headerImageDesktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    cliniques: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            address: PropTypes.shape({
              coordonate: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
              }).isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query {
    headerImageDesktop: file(name: { eq: "img-header-desktop-nos-cliniques" }) {
      ...HeroWithTextLargePictureData
    }

    headerImageMobile: file(name: { eq: "img-header-mobile-nos-cliniques" }) {
      ...HeroWithTextSmallPictureData
    }

    cliniques: allSanityLocation {
      edges {
        node {
          address {
            coordonate {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
